import { CreateControllerFn } from 'yoshi-flow-editor-runtime';

const createController: CreateControllerFn = async ({ flowAPI, controllerConfig }) => {
  // For more info about controller structure,
  // check the docs: https://bo.wix.com/pages/yoshi/docs/editor-flow/structure-api/component#controller
  return {
    async pageReady() {
      // Here you can pass props to the Widget
      const experiments = await flowAPI.getExperiments();
      const data = flowAPI.controllerConfig.config.publicData.APP;
      const rcpString = data.rcp;
      let rcpConfig = rcpString && JSON.parse(decodeURIComponent(rcpString));
      if (!rcpConfig) {
        const testConfig = { t: 'Copyrights' };
        rcpConfig = testConfig;
      }
      controllerConfig.setProps({
        rcpConfig,
      });
    },
  };
};

export default createController;
