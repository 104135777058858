
  import userController from '/home/builduser/agent00/work/5d9827cfe03d3bf1/right-click-protect/src/components/RightClickProtect/controller.ts';
  import createControllerWrapper from 'yoshi-flow-editor-runtime/build/esm/controllerWrapper.js';
  import {
    
  } from 'yoshi-flow-editor-runtime/build/esm/controller/httpClientProp';

  var sentryConfig = {
    DSN: 'https://261938f6d0c848dfb768387e4cb17694@sentry.wixpress.com/1142',
    id: '261938f6d0c848dfb768387e4cb17694',
    projectName: 'right-click-protect',
    teamName: 'photography',
  };

  var experimentsConfig = {"scope":"test"};

  var translationsConfig = {"default":"en","defaultTranslationsPath":"/home/builduser/agent00/work/5d9827cfe03d3bf1/right-click-protect/src/assets/locales/messages_en.json"};

  var biConfig = {"owner":"bi-logger-editor-flow-template","visitor":"bi-logger-editor-flow-template","enableUniversalEvents":false};

  var defaultTranslations = {"app.widget.welcome":"Welcome","app.settings.label":"Configure Widget","app.settings.tabs.main":"Main","app.settings.tabs.design":"Design","app.settings.defaults.greetingsPretext":"to"};

  import biLogger from '/home/builduser/agent00/work/5d9827cfe03d3bf1/right-click-protect/node_modules/bi-logger-editor-flow-template/dist/src/index.js';

  const _controller = createControllerWrapper(userController, {
    sentryConfig,
    biConfig,
    experimentsConfig,
    biLogger,
    translationsConfig,
    appName: "Right Click Protect",
    appDefinitionId: "1429e204-2254-7f40-836b-5ffd769ac4c0",
    componentId: "1429e225-f6b0-2230-82f6-9a6aa1192f8f",
    projectName: "right-click-protect",
    defaultTranslations,
  }, {
     
  });
  export const controller = _controller;
  export default _controller;
